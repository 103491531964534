import { makeStyles } from "@material-ui/core/styles";
import { observer } from "mobx-react";
import React from "react";
import { BrowserRouter, Redirect, Route, Switch } from "react-router-dom";
import HomeScreen from "../../features/home/HomeScreen";
import appStore from "../store/appStore";

import loadable from "@loadable/component";
import ArticleScreen from "../../features/blog/ArticleScreen";
import CountryScreen from "../../features/country/CountryScreen";

const PresentScreen = loadable(() => import("../../features/present/PresentScreen"));

const DocsScreen = loadable(() => import("../../features/docs/DocsScreen"));
const EditDocScreen = loadable(() => import("../../features/docs/EditDocScreen"));
const InviteScreen = loadable(() => import("../../features/invite/InviteScreen"));
const JoinScreen = loadable(() => import("../../features/invite/JoinScreen"));
const NotFoundScreen = loadable(() => import("../../features/notfound"));
const PaymentScreen = loadable(() => import("../../features/payment"));
const PricingScreen = loadable(() => import("../../features/pricing"));
const TermsScreen = loadable(() => import("../../features/legal/TermsScreen"));
const PrivacyScreen = loadable(() => import("../../features/legal/PrivacyScreen"));
const BlogScreen = loadable(() => import("../../features/blog/BlogScreen"));

function Router() {
  const useStyles = makeStyles(theme => ({
    root: {
      display: "flex",
      backgroundColor: "#f9fafa",
    },

    toolbar: theme.mixins.toolbar,

    content: {
      flexGrow: 1,
      backgroundColor: "#f9fafa",
    },
  }));

  const classes = useStyles();

  // Properties
  const { user } = appStore;

  return (
    <BrowserRouter basename={process.env.REACT_APP_BASENAME}>
      <div className={classes.root}>
        <main className={classes.content}>
          <Switch>
            <Route path="/" exact>
              {user ? <DocsScreen /> : <HomeScreen />}
            </Route>

            <Route path="/docs">{user ? <DocsScreen /> : <Redirect to="/" />}</Route>

            {false && (
              <Route path="/payment">
                <PaymentScreen />
              </Route>
            )}

            {false && (
              <Route path="/blog/roomview-for-real-estate-agents">
                <ArticleScreen />
              </Route>
            )}

            {false && (
              <Route path="/blog">
                <BlogScreen />
              </Route>
            )}

            {false && (
              <Route path="/pricing">
                <PricingScreen />
              </Route>
            )}

            <Route path="/country">
              <CountryScreen />
            </Route>

            <Route path="/terms">
              <TermsScreen />
            </Route>

            <Route path="/privacy">
              <PrivacyScreen />
            </Route>

            {false && (
              <Route path="/invites">{user ? <InviteScreen /> : <Redirect to="/" />}</Route>
            )}

            {false && (
              <Route path="/join/:inviteId">{user ? <Redirect to="/" /> : <JoinScreen />}</Route>
            )}

            <Route path="/editdoc/:docId">{user ? <EditDocScreen /> : <Redirect to="/" />}</Route>

            {false && (
              <Route path="/present/:docId">
                <PresentScreen />
              </Route>
            )}

            <Route>
              <NotFoundScreen />
            </Route>
          </Switch>
        </main>
      </div>
    </BrowserRouter>
  );
}

export default observer(Router);
