import { Button, Container } from "@material-ui/core";
import { useTheme } from "@material-ui/core/styles";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import React from "react";
import { BodyLarge, BodyMedium, Heading1 } from "../../../components/typography";

import loadable from "@loadable/component";
import { Col, Row } from "../../../components/grid";
import Bar from "../../../components/Bar/Bar";

const SignUpDialog = loadable(() => import("../../login/SignUpDialog/SignUpDialog"));

export default function HomeHeader() {
  const theme = useTheme();
  const smAndUp = useMediaQuery(theme.breakpoints.up("sm"));
  const [showSignUpDialog, setShowSignUpDialog] = React.useState(false);

  const headerStyle = smAndUp ? { fontSize: 60 } : { fontSize: 35, lineHeight: "40px" };
  const textStyle = smAndUp ? {} : { fontSize: 14, maxWidth: 310 };

  return (
    <div style={{}}>
      {showSignUpDialog && <SignUpDialog onClose={() => setShowSignUpDialog(false)} />}

      <Container
        style={{
          maxWidth: 1440,
          textAlign: "left",
          backgroundSize: "cover",
          backgroundPosition: "bottom",
          backgroundRepeat: "no-repeat",
          paddingLeft: smAndUp ? 90 : 20,
          paddingTop: smAndUp ? 210 : 150,
          paddingBottom: smAndUp ? 210 : 150,
          backgroundImage: smAndUp ? "url(/countrybg.png)" : "url(/countrybg.png)",
        }}>
        <Heading1
          style={{
            color: "#fff",
            maxWidth: 710,
            lineHeight: "54px",
            textAlign: "left",
            fontWeight: "bold",
            ...headerStyle,
          }}>
          Ethiopia
        </Heading1>

        <BodyMedium
          style={{
            color: "#fff",
            fontSize: 22,
            maxWidth: 900,
            marginBottom: 20,
            marginTop: smAndUp ? 27 : 10,
            ...textStyle,
          }}>
          We have offices and teams all around the world to assist you.{" "}
        </BodyMedium>
      </Container>
    </div>
  );
}
