import firebase from "firebase/compat/app";
const config = {
  apiKey: "AIzaSyCb7LzYvUCd6ZXPAwhn94REtKDy7vBmIF8",
  authDomain: "gasaviationw.firebaseapp.com",
  projectId: "gasaviationw",
  storageBucket: "gasaviationw.appspot.com",
  messagingSenderId: "1056410695842",
  appId: "1:1056410695842:web:5e6c0145da944b1b203ffb",
};

firebase.initializeApp(config);

export default firebase;
