import React from "react";
import Bar from "../../components/Bar/Bar";
import FooterView from "../../components/FooterView";
import HomeHeader from "./ui/HomeHeader";
import HomeHow from "./ui/HomeHow";

import loadable from "@loadable/component";
import HomeTryIt from "./ui/HomeTryIt";
import HomeUsecases from "./ui/HomeUsecases";
import HomeServices from "./ui/HomeServices";
import HomeFooterSection from "./ui/HomeFooterSection";
const ContentView = loadable(() => import("./ContentView"));

export default function CountryScreen() {
  return (
    <div style={{ background: "#171E34" }}>
      <Bar />
      <HomeHeader />

      <HomeServices />
      {false && <HomeTryIt />}
      {false && <HomeHow />}
      {false && <HomeUsecases />}
      {false && <ContentView />}

      <HomeFooterSection />
      <FooterView />
    </div>
  );
}
