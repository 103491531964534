import { Container } from "@material-ui/core";
import { useTheme } from "@material-ui/core/styles";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import React from "react";

export default function HomeFooterSection() {
  const theme = useTheme();
  const smAndUp = useMediaQuery(theme.breakpoints.up("sm"));

  return (
    <Container
      style={{
        maxWidth: 1440,
        marginTop: 100,
        textAlign: "left",
        minHeight: 300,
        backgroundSize: "cover",
        backgroundPosition: "bottom",
        backgroundRepeat: "no-repeat",
        paddingLeft: smAndUp ? 90 : 20,
        paddingTop: smAndUp ? 210 : 150,
        backgroundImage: smAndUp ? "url(/footer.png)" : "url(/footer.png)",
      }}></Container>
  );
}
