import { Button, Container } from "@material-ui/core";
import { useTheme } from "@material-ui/core/styles";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import React from "react";
import { BodyMedium, Heading1 } from "../../../components/typography";

import { Col, Row } from "../../../components/grid";

export default function HomeServices() {
  const theme = useTheme();
  const smAndUp = useMediaQuery(theme.breakpoints.up("sm"));

  const headerStyle = smAndUp ? { fontSize: 60 } : { fontSize: 35, lineHeight: "40px" };
  const textStyle = smAndUp ? {} : { fontSize: 14, maxWidth: 310 };

  return (
    <div style={{}}>
      <Container
        style={{
          maxWidth: 1440,
          paddingTop: 20,
          paddingBottom: 20,
          textAlign: "left",
          backgroundSize: "contain",
          backgroundPosition: "bottom",
          backgroundRepeat: "no-repeat",
        }}>
        <Row style={{ minHeight: 400, paddingTop: 40 }}>
          <Col>
            <Heading1
              style={{
                color: "#fff",
                maxWidth: 710,
                lineHeight: "54px",
                fontWeight: "bold",
                marginLeft: "auto",
                textAlign: "right",
                paddingTop: 20,
                ...headerStyle,
                fontSize: 36,
              }}>
              GAS: What makes us different
            </Heading1>
          </Col>
          <Col>
            <BodyMedium
              style={{
                color: "#fff",
                fontSize: 22,
                marginBottom: 20,
                marginTop: smAndUp ? 27 : 10,
                ...textStyle,
                maxWidth: 410,
              }}>
              Specialists in transparent Permits, Ground Handling, Fuel and Logistical Support
              solutions across the African continent, offering reliable and cost-effective services.
              Working directly with issuing authorities and physically based service providers
              ensures that G.A.S. is able to support over 90% of our customer’s needs directly with
              the ultimate service providers on the ground in each country.
            </BodyMedium>
          </Col>
        </Row>

        <Row style={{ marginTop: 100 }}>
          <Card
            bg={1}
            to="#services"
            title="African Landing & Overfly Permits"
            subtitle="We obtain over 90% of all our permits directly from the issuing authorities, for optimal time & cost efficiencies!"
            {...{ headerStyle, textStyle, smAndUp }}
          />
          <Card
            bg={2}
            to="#services"
            title="Regional Ground Handling"
            subtitle="Our own boots on the ground in key countries delivering the ultimate peace of mind for your successful operations."
            {...{ headerStyle, textStyle, smAndUp }}
          />
          <Card
            bg={3}
            to="#fuel"
            title={<>Aviation Fuel Solutions</>}
            subtitle="Fuel plays a pivotal role in trip planning from both reliability and cost prospective, and our direct solutions are ready to support your operations."
            {...{ headerStyle, textStyle, smAndUp }}
          />
          <Card
            bg={4}
            to="#services"
            title={<>Non-Aviation Logistics</>}
            subtitle="Over a century’s’ combined in-house experience in supporting your every need on the ground from ground transportation and hotels to safari arrangements and in-flight catering"
            {...{ headerStyle, textStyle, smAndUp }}
          />
        </Row>

        <Row id="aboutus" style={{ minHeight: 400, paddingTop: 100 }}>
          <Col>
            <Heading1
              style={{
                color: "#fff",
                maxWidth: 710,
                lineHeight: "54px",
                fontWeight: "bold",
                paddingTop: 20,
                ...headerStyle,
                fontSize: 36,
              }}>
              Welcome to G.A.S. General Aviation Services
            </Heading1>

            <BodyMedium
              style={{
                color: "#fff",
                fontSize: 22,
                marginBottom: 20,
                marginTop: smAndUp ? 27 : 10,
                ...textStyle,
              }}>
              Incorporated and Headquartered in London (UK) with a 24/7 operations team based across
              Mwanza (Tanzania), Kigali (Rwanda) and Cairo (Egypt). Established in 2014, we quickly
              gained momentum across the African continent, forming working partnerships with local
              service providers and industry leading aircraft operators and service providers.
              Today, our roots continue to grow deeper, providing trusted services to our global
              partners and their flights across all 54 African countries. Centring all services on
              our core values: transparency, efficiency and reliability.
            </BodyMedium>
          </Col>
          <Col>
            <Heading1
              style={{
                color: "#fff",
                maxWidth: 710,
                lineHeight: "54px",
                fontWeight: "bold",
                paddingTop: 20,
                ...headerStyle,
                fontSize: 36,
              }}>
              What makes us different
            </Heading1>

            <BodyMedium
              style={{
                color: "#fff",
                fontSize: 22,
                marginBottom: 20,
                marginTop: smAndUp ? 27 : 10,
                ...textStyle,
              }}>
              Permits and Ground Handling solutions that come with our unique quote to invoice
              guarantee that enables accurate trip cost planning for any flights across the African
              continent, whilst offering reliable and cost-effective Fuel options. However we
              believe our most important service is the complete peace of mind we deliver to our
              partners daily, whether they be aircraft operators or service companies, we guarantee
              true time and cost efficiencies.
            </BodyMedium>
          </Col>
        </Row>

        <Row style={{ minHeight: 400, paddingTop: 40 }}>
          <Col>
            <Heading1
              style={{
                color: "#fff",
                maxWidth: 710,
                lineHeight: "54px",
                fontWeight: "bold",
                paddingTop: 20,
                ...headerStyle,
                fontSize: 36,
              }}>
              Mission
            </Heading1>

            <BodyMedium
              style={{
                color: "#fff",
                fontSize: 22,
                marginBottom: 20,
                marginTop: smAndUp ? 27 : 10,
                ...textStyle,
              }}>
              To deliver outstanding value, continuous innovation and a customer-centric solutions
              in the aviation industry. We strive to deliver exceptional experience by prioritising
              our customs' needs, through dedication, integrity and professionalism, we aim to
              foster enduring partnerships with our client and vendors built on trust and
              reliability.
            </BodyMedium>
          </Col>
          <Col>
            <Heading1
              style={{
                color: "#fff",
                maxWidth: 710,
                lineHeight: "54px",
                fontWeight: "bold",
                paddingTop: 20,
                ...headerStyle,
                fontSize: 36,
              }}>
              Vision
            </Heading1>

            <BodyMedium
              style={{
                color: "#fff",
                fontSize: 22,
                marginBottom: 20,
                marginTop: smAndUp ? 27 : 10,
                ...textStyle,
              }}>
              To be the partner of choice on for Service Providing Companies and Aircraft Operators
              alike in their quest for reliable and cost-effective aviation support services across
              the African continent.
            </BodyMedium>
          </Col>
        </Row>

        <Row id="services" style={{ minHeight: 400, paddingTop: 100 }}>
          <Col>
            <Heading1
              style={{
                color: "#fff",
                maxWidth: 710,
                lineHeight: "54px",
                fontWeight: "bold",
                paddingTop: 20,
                ...headerStyle,
                fontSize: 36,
              }}>
              Landing & Overfly Permits
            </Heading1>

            <BodyMedium
              style={{
                color: "#fff",
                fontSize: 22,
                marginBottom: 20,
                marginTop: smAndUp ? 27 : 10,
                ...textStyle,
              }}>
              <p>We obtain over 90% of all our permits directly from the issuing authorities!</p>
              Direct working relationships with Civil Aviation Authorities (CAA's) or their
              appointed representatives across Africa ensures we can obtain your landing & overfly
              permits at short notice, delivering the best quote to invoice value and peace of mind
              as standard. Established lines of credit that allow G.A.S. to collect navigation fee
              charges at difficult locations or handle prepayment where mandatory. Specialists in
              obtaining block permits for Schedule and non-Schedule carries along with alternative
              route permits.
            </BodyMedium>
            <div
              style={{
                background: "#fff",
                border: "2px solid #fff",
                paddingLeft: 24,
                paddingRight: 24,
              }}>
              <Heading1
                style={{
                  color: "#171E34",
                  maxWidth: 710,
                  lineHeight: "54px",
                  fontWeight: "bold",
                  paddingTop: 20,
                  ...headerStyle,
                  fontSize: 36,
                }}>
                Permits directly from the source
              </Heading1>

              <BodyMedium
                style={{
                  color: "#171E34",
                  fontSize: 22,
                  marginBottom: 20,
                  marginTop: smAndUp ? 27 : 10,
                  ...textStyle,
                }}>
                Over 90% of our permits obtained directly from national CAA's
              </BodyMedium>
            </div>
          </Col>

          <Col>
            <Heading1
              style={{
                color: "#fff",
                maxWidth: 710,
                lineHeight: "54px",
                fontWeight: "bold",
                paddingTop: 20,
                ...headerStyle,
                fontSize: 36,
              }}>
              Ground Handling
            </Heading1>

            <BodyMedium
              style={{
                color: "#fff",
                fontSize: 22,
                marginBottom: 20,
                marginTop: smAndUp ? 27 : 10,
                ...textStyle,
              }}>
              <p style={{ fontSize: 26 }}>We've got your back across Africa</p>

              <p>100 not out!</p>

              <p>
                Over a century's' combined in-house experience of physical, supervisory and
                coordination solutions, supported by our own staff and an established network of
                supervisory agents on the ground across the continent, makes us your partner of
                choice for your ground handling needs in Africa.
              </p>
              <p>
                Contact us today to see how we can save you time and money on your ground handling
                needs wherever your flights take you across the African region.
              </p>
            </BodyMedium>
          </Col>
        </Row>

        <Row id="fuel" style={{ minHeight: 400, paddingTop: 100 }}>
          <Col>
            <Heading1
              style={{
                color: "#fff",
                maxWidth: 710,
                lineHeight: "54px",
                fontWeight: "bold",
                paddingTop: 20,
                ...headerStyle,
                fontSize: 36,
              }}>
              Focused African Fuel Network
            </Heading1>

            <BodyMedium
              style={{
                color: "#fff",
                fontSize: 22,
                marginBottom: 20,
                marginTop: smAndUp ? 27 : 10,
                ...textStyle,
              }}>
              Focused African Fuel Network Our unrivalled local expertise and direct in region
              supply relationships allow G.A.S. to secure competitive rates and deliver peace of
              mind to our client partners. Securing fuel where it’s needed when it is needs is
              crucial in enabling aircraft operators to successfully complete their missions even in
              the most remote of locations.
            </BodyMedium>
          </Col>
          <Col>
            <Heading1
              style={{
                color: "#fff",
                maxWidth: 710,
                lineHeight: "54px",
                fontWeight: "bold",
                paddingTop: 20,
                ...headerStyle,
                fontSize: 36,
              }}>
              Global Fuel Services
            </Heading1>

            <BodyMedium
              style={{
                color: "#fff",
                fontSize: 22,
                marginBottom: 20,
                marginTop: smAndUp ? 27 : 10,
                ...textStyle,
              }}>
              Fuel plays a pivotal role in trip planning and aircraft operations; fuel can heavily
              influence the total cost or overall success of a flight; we understand that you need a
              partner you can trust and rely upon to deliver value for money fuel options with
              quality service. Thanks to our extensive network of local suppliers, into-plane agents
              and reselling partners, we are able to provide fuel service around the global whilst
              being your specialist partner for fuel in the African region. No matter what the size
              of your fleet of type of aircraft our into-plane partners are ready 24/7/365 to refuel
              over the wing and single point.
            </BodyMedium>
          </Col>
        </Row>

        <Row id="fuel" style={{ minHeight: 400, paddingTop: 100 }}>
          <Col>
            <Heading1
              style={{
                color: "#fff",
                maxWidth: 710,
                lineHeight: "54px",
                fontWeight: "bold",
                paddingTop: 20,
                ...headerStyle,
                fontSize: 36,
              }}>
              Non-Aviation Logistics
            </Heading1>

            <BodyMedium
              style={{
                color: "#fff",
                fontSize: 22,
                marginBottom: 20,
                marginTop: smAndUp ? 27 : 10,
                ...textStyle,
              }}>
              Whilst our dedicated support of your flight operations are crucial to successful
              missions, we are also there to support you with every detail of what your crews and
              passengers require in between arrival and departures to ensure an unforgettable trip
              to Africa. From room arrangements with local and international hotel brands, to safe
              and suitable ground transportation options, to VIP excursions delivering the unique
              essence of Africa from the Egyptian pyramids to safaris in Tanzania and South Africa.
            </BodyMedium>
          </Col>
          <Col></Col>
        </Row>
      </Container>
    </div>
  );
}

function Card({ to, bg, title, subtitle, headerStyle, textStyle, smAndUp }) {
  return (
    <Col md={3} lg={3} style={{}}>
      <div
        style={{
          marginLeft: 0,
          marginRight: 0,
          height: 500,
          position: "relative",
          backgroundImage: "url(/" + bg + ".png)",
        }}>
        <div
          style={{
            top: 0,
            left: 0,
            right: 0,
            bottom: 0,
            position: "absolute",
            backgroundColor: "#0A1434cc",
          }}
        />

        <div
          style={{
            top: 0,
            left: 0,
            right: 0,
            bottom: 0,
            padding: 16,
            position: "absolute",
          }}>
          <Heading1
            style={{
              height: 160,
              color: "#fff",
              maxWidth: 710,
              paddingTop: 50,
              textAlign: "left",
              lineHeight: "54px",
              fontWeight: "bold",
              ...headerStyle,
              fontSize: 36,
            }}>
            {title}
          </Heading1>

          <BodyMedium
            style={{
              height: 220,
              color: "#fff",
              fontSize: 22,
              maxWidth: 900,
              marginBottom: 20,
              marginTop: smAndUp ? 27 : 10,
              ...textStyle,
            }}>
            {subtitle}
          </BodyMedium>

          <Button
            color="primary"
            variant="contained"
            href={to}
            style={{ marginRight: 8, marginBottom: 40, height: 46, color: "#fff", width: 170 }}>
            Learn More
          </Button>
        </div>
      </div>
    </Col>
  );
}
