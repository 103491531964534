import { Container } from "@material-ui/core";
import { useTheme } from "@material-ui/core/styles";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import React from "react";
import { Col, Row } from "../../../components/grid";
import { BodyMedium, Heading1 } from "../../../components/typography";

export default function HomeFooterSection() {
  const theme = useTheme();
  const smAndUp = useMediaQuery(theme.breakpoints.up("sm"));

  return (
    <Container
      style={{
        maxWidth: 1440,
        marginTop: 100,
        textAlign: "left",
        minHeight: 300,
        backgroundSize: "cover",
        backgroundPosition: "bottom",
        backgroundRepeat: "no-repeat",
        backgroundImage: smAndUp ? "url(/footer.png)" : "url(/footer.png)",
      }}>
      <Row style={{ paddingTop: 40 }}>
        <Col>
          <Heading1
            style={{
              color: "#fff",
              maxWidth: 710,
              fontSize: 24,
              lineHeight: "24px",
              textAlign: "left",
              fontWeight: "bold",
              marginBottom: 24,
            }}>
            Contact
          </Heading1>
          <BodyMedium
            style={{
              color: "#fff",
              fontSize: 22,
              maxWidth: 900,
              marginBottom: 20,
            }}>
            <div>Tel: +44 (0) 208 144 1288 (24/7 Ops)</div>
            <div>Email: ops24@gasaviation.com</div>
          </BodyMedium>

          <Heading1
            style={{
              color: "#fff",
              maxWidth: 710,
              fontSize: 24,
              lineHeight: "24px",
              textAlign: "left",
              fontWeight: "bold",
              marginBottom: 24,
            }}>
            United Kingdom
          </Heading1>
          <BodyMedium
            style={{
              color: "#fff",
              fontSize: 22,
              maxWidth: 900,
              marginBottom: 20,
            }}>
            <div>
              Office 505
              <br />
              Cannon Wharf
              <br />
              Pell Street
              <br /> Surrey Quays
              <br /> London
            </div>
          </BodyMedium>
        </Col>
      </Row>
    </Container>
  );
}
