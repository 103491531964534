import { createTheme } from "@material-ui/core/styles";
import Colors from "../components/Colors";
import tinycolor from "tinycolor2";

// Define the main color
const mainColor = "#A0363D";

// Calculate light and dark shades from the main color
const lightColor = tinycolor(mainColor)
  .lighten(15)
  .toString();
const darkColor = tinycolor(mainColor)
  .darken(15)
  .toString();

const defaultTheme = createTheme({
  props: {
    MuiButton: {
      disableElevation: true,
    },
  },
  status: {
    danger: "#e53e3e",
  },
  palette: {
    primary: {
      light: lightColor,
      main: mainColor,
      dark: darkColor,
      contrastText: "#fff",
    },
    secondary: {
      light: "#79C8EF",
      main: "#40A0D0",
      dark: "#0B4B76",
      contrastText: "#000",
    },
    success: {
      light: "#79C8EF",
      main: "#FD5E5A",
      dark: "#0B4B76",
      contrastText: "#000",
    },
    common: {
      black: "#16181A",
    },
  },
  typography: {
    fontFamily: "Barlow",
    button: {
      textTransform: "none",
    },
  },
  overrides: {
    MuiButton: {
      root: {
        paddingTop: 11,
        paddingLeft: 41,
        paddingRight: 41,
        borderRadius: 0,
        paddingBottom: 11,
        fontFamily: "Barlow",
        fontWeight: "bold",
      },
    },
  },
  components: {
    MuiCssBaseline: {
      styleOverrides: `
      body{
        backgroundColor: ${Colors.Gray.G50}
      }
       
      `,
    },
  },
});

export default {
  defaultTheme,
};
