import { Grid } from "@material-ui/core";
import React from "react";

export function Row(props) {
  return (
    <div>
      <Grid container spacing={3} {...props} />
    </div>
  );
}

export function Col(props) {
  return <Grid item xs={12} sm={6} {...props} />;
}
