import { Container } from "@material-ui/core";
import { useTheme } from "@material-ui/core/styles";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import React from "react";
import { BodyLarge, BodyMedium, Heading1 } from "../../../components/typography";

import { Col, Row } from "../../../components/grid";

export default function HomeServices() {
  const theme = useTheme();
  const smAndUp = useMediaQuery(theme.breakpoints.up("sm"));
  const textStyle = smAndUp ? {} : { fontSize: 14, maxWidth: 310 };
  const headerStyle = smAndUp ? { fontSize: 60 } : { fontSize: 35, lineHeight: "40px" };

  return (
    <div style={{}}>
      <Container
        style={{
          maxWidth: 1440,
          paddingTop: 20,
          textAlign: "left",
          paddingBottom: 20,
          backgroundSize: "contain",
          backgroundPosition: "bottom",
          backgroundRepeat: "no-repeat",
        }}>
        <Row style={{ maxWidth: 1000, padding: 20 }}>
          <Heading1
            style={{
              color: "#fff",
              paddingTop: 20,
              lineHeight: "54px",
              fontWeight: "bold",
              ...headerStyle,
              fontSize: 36,
            }}>
            Ground Handling
          </Heading1>
          <BodyMedium
            style={{
              color: "#fff",
              fontSize: 22,
              marginBottom: 20,
              marginTop: smAndUp ? 27 : 10,
              ...textStyle,
            }}>
            Specialising in transparent Permits, Ground Handling and Concierge solutions across the
            African continent, whilst offering reliable and cost-effective Fuel options globally.
          </BodyMedium>

          <BodyMedium
            style={{
              color: "#fff",
              fontSize: 22,
              marginBottom: 100,
              marginTop: smAndUp ? 27 : 10,
              ...textStyle,
            }}>
            Specialising in transparent Permits, Ground Handling and Concierge solutions across the
            African continent, whilst offering reliable and cost-effective Fuel options globally.
            Working with issuing authorities and physically based service providers, ensuring that
            over 83% of our customer’s needs are supported directly by the aultimate service
            providers on the ground in each country. Working with issuing authorities and physically
            based service providers, ensuring that over 83% of our customer’s needs are supported
            directly by the aultimate service providers on the ground in each country.
          </BodyMedium>
        </Row>

        <Row
          style={{
            maxWidth: 1440,
            textAlign: "left",
            backgroundSize: "cover",
            backgroundPosition: "bottom",
            backgroundRepeat: "no-repeat",
            paddingLeft: smAndUp ? 90 : 20,
            paddingTop: smAndUp ? 210 : 150,
            paddingBottom: smAndUp ? 210 : 150,
            backgroundImage: smAndUp ? "url(/countryimg.png)" : "url(/countryimg.png)",
          }}
        />

        <Row style={{ paddingTop: 40 }}>
          <Col>
            <Heading1
              style={{
                color: "#fff",
                maxWidth: 710,
                paddingTop: 20,
                lineHeight: "54px",
                fontWeight: "bold",
                marginLeft: "auto",
                ...headerStyle,
                fontSize: 36,
              }}>
              G.A.S. Mwanza / HTMW / MWZ
            </Heading1>
          </Col>
        </Row>

        <Row style={{ minHeight: 400, paddingTop: 40 }}>
          {[1, 2].map(item => (
            <Col key={item}>
              <Heading1
                style={{
                  color: "#fff",
                  maxWidth: 710,
                  paddingTop: 20,
                  lineHeight: "54px",
                  fontWeight: "bold",
                  marginLeft: "auto",
                  ...headerStyle,
                  fontSize: 36,
                }}>
                G.A.S. Services
              </Heading1>

              <ul>
                {[
                  "Full ground handling supervision",
                  "Own staff with Airside access",
                  "Permit coordination",
                  "Contract fuel",
                  "Credit line",
                  "Hotel arrangements",
                ].map(item => (
                  <li key={item} style={{ color: "#fff", fontSize: 26 }}>
                    {item}
                  </li>
                ))}
              </ul>
            </Col>
          ))}
        </Row>

        <Row style={{ paddingTop: 40 }}>
          <Col>
            <Heading1
              style={{
                color: "#fff",
                maxWidth: 710,
                paddingTop: 20,
                lineHeight: "54px",
                fontWeight: "bold",
                marginLeft: "auto",
                ...headerStyle,
                fontSize: 36,
              }}>
              Airport Information
            </Heading1>
          </Col>
        </Row>

        <Row style={{ minHeight: 400, paddingTop: 40 }}>
          {[
            [
              { label: "Airport name", value: "Mwanza Airport" },
              { label: "ICAO", value: "HTMW" },
              { label: "IATA", value: "MWZ" },
            ],
            [
              { label: "Location", value: "View map" },
              { label: "Time Zone", value: "UTC +3" },
              { label: "Elevation", value: "3,763 ft. / 1,147 m" },
            ],
          ].map(row => {
            return row.map(r => {
              return (
                <Col key={r.label} xs={12} sm={6} md={4}>
                  <div
                    style={{
                      padding: 24,
                      marginRight: 8,
                      backgroundColor: "#1F2847",
                      borderRight: "10px solid #2E3753",
                    }}>
                    <BodyLarge
                      style={{
                        color: "#fff",
                        paddingTop: 20,
                        lineHeight: "54px",
                        fontWeight: "bold",
                        marginLeft: "auto",
                        ...headerStyle,
                        fontSize: 36,
                      }}>
                      {r.label}
                    </BodyLarge>

                    <BodyMedium style={{ color: "#fff" }}>{r.value}</BodyMedium>
                  </div>
                </Col>
              );
            });
          })}
        </Row>

        <Row style={{ paddingTop: 40 }}>
          <Col>
            <Heading1
              style={{
                color: "#fff",
                maxWidth: 710,
                paddingTop: 20,
                lineHeight: "54px",
                fontWeight: "bold",
                marginLeft: "auto",
                ...headerStyle,
                fontSize: 36,
              }}>
              Station Manager
            </Heading1>
          </Col>
        </Row>

        <Row style={{ paddingTop: 40 }}>
          <Col style={{ maxWidth: 200 }}>
            <img style={{ maxWidth: 200 }} src={"/manager.png"} />
            <BodyMedium style={{ color: "#fff" }}>Hussein Qubah</BodyMedium>
          </Col>
          <Col style={{ paddingLeft: 48 }}>
            <BodyMedium style={{ color: "#fff" }}>
              <p>
                A dedicated operations professional with over 15 years experience, Hussein
                specialises in general aviation management with specific responsibility for both
                operations and back office administration. Hussein is driven to ensure that our
                client expectations are exceeded through delivery of high-quality services.
              </p>

              <p>
                Hussein is appreciated by clients for quick/timely turnarounds, leveraging his
                expertise in African business aviation and providing high levels of customer
                service.
              </p>

              <p>
                Hussein’s goal is to ensure smooth and trouble-free client operations through what
                can be a very complex region to operate within.
              </p>
            </BodyMedium>
          </Col>
        </Row>
      </Container>
    </div>
  );
}
