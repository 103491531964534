import { Button, Container } from "@material-ui/core";
import { useTheme } from "@material-ui/core/styles";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import React from "react";
import { BodyLarge, BodyMedium, Heading1 } from "../../../components/typography";

import loadable from "@loadable/component";
import { Col, Row } from "../../../components/grid";

const SignUpDialog = loadable(() => import("../../login/SignUpDialog/SignUpDialog"));

export default function HomeHeader() {
  const theme = useTheme();
  const smAndUp = useMediaQuery(theme.breakpoints.up("sm"));
  const [showSignUpDialog, setShowSignUpDialog] = React.useState(false);

  const headerStyle = smAndUp ? { fontSize: 60 } : { fontSize: 35, lineHeight: "40px" };
  const textStyle = smAndUp ? {} : { fontSize: 14, maxWidth: 310 };

  return (
    <div id="home" style={{}}>
      {showSignUpDialog && <SignUpDialog onClose={() => setShowSignUpDialog(false)} />}

      <Container
        style={{
          maxWidth: 1440,
          paddingBottom: 40,
          textAlign: "left",
          backgroundSize: "cover",
          backgroundPosition: "bottom",
          backgroundRepeat: "no-repeat",
          paddingLeft: smAndUp ? 90 : 20,
          paddingTop: smAndUp ? 210 : 150,
          backgroundImage: smAndUp ? "url(/headerbg.png)" : "url(/headerbg.png)",
        }}>
        <Heading1
          style={{
            color: "#fff",
            maxWidth: 710,
            lineHeight: "54px",
            textAlign: "left",
            fontWeight: "bold",
            ...headerStyle,
          }}>
          No.1 Provider of Permit
          <br /> & Ground Handling Solutions Across Africa
        </Heading1>

        <BodyMedium
          style={{
            color: "#fff",
            fontSize: 22,
            maxWidth: 900,
            marginBottom: 20,
            marginTop: smAndUp ? 27 : 10,
            ...textStyle,
          }}>
          Sense the urgency! Delivering peace of mind through quality service
        </BodyMedium>

        <Button
          href="#services"
          color="primary"
          variant="contained"
          style={{ marginRight: 8, height: 46, color: "#fff", width: 170 }}>
          Explore More
        </Button>

        <Bullets {...{ smAndUp }} />
      </Container>
    </div>
  );
}

function Bullets({ smAndUp }) {
  const content = (
    <>
      <IconTextSubtitle
        src="/planetplane.svg"
        title="100+"
        subtitle="years of combined African Aviation exprience"
      />
      <IconTextSubtitle src="/planet.svg" title={54} subtitle="All 54 African countries covered" />
      <IconTextSubtitle
        src="/plane.svg"
        title={50}
        subtitle="Over 50 international brands us GAS"
      />
    </>
  );

  return (
    <div style={{ marginTop: 60, marginBottom: 20 }}>
      {smAndUp ? <Row>{content}</Row> : content}
    </div>
  );
}
function IconTextSubtitle({ src, title, subtitle }) {
  return (
    <Col md={4} lg={4} style={{ display: "flex" }}>
      <img src={src} alt="plane" style={{ display: "inline-block" }} />
      <div
        style={{
          height: 80,
          color: "#fff",
          paddingLeft: 20,
          display: "inline-block",
        }}>
        <BodyLarge style={{ fontSize: 40 }}>{title}</BodyLarge>
        <BodyMedium style={{ fontSize: 20 }}>{subtitle}</BodyMedium>
      </div>
    </Col>
  );
}
